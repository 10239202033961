import React from 'react'
import styled from 'styled-components'
import {CLOUDINARY_PATH} from './../constants/constants'

export default function AboutUs() {
  return (
    <StyledComponent id="about-us">
      <div className="container">
        <h3 className="section-heading">About us</h3>
        <div className="row">
          <p>
            Whether you&rsquo;re planning a new-build home, loft conversion, extension or general building work, get in touch with us at{' '}
            <span className="no-break">T J Buckfield Ltd.</span> We&rsquo;ve completed hundreds of projects in the Dorset area since 1990, and will take care of everything with our
            complete project management service.
          </p>
          <p>
            Everything we do is fully backed up with NHBC and CRL certification, so leave it all to us to build your ideas into reality. <a href="#contact-us">Contact us</a> to see
            how we can help with your next building project.
          </p>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  background-attachment: fixed;
  background-image: url(${CLOUDINARY_PATH}roof-tiles-light.jpg);
  background-position: top center;
  background-size: 113rem;
  padding: 8rem 3.5rem;

  a {
    color: #333;
  }

  .no-break {
    white-space: nowrap;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    .row {
      flex-direction: row;
    }
    p {
      margin: 0 6rem 0 0;
      width: 50%;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 1024px) {
    background-size: 186rem;
  }

  @media (min-width: 1800px) {
    background-size: cover;
  }
`
