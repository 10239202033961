import React from 'react'
import styled from 'styled-components'
import {CLOUDINARY_PATH} from './../constants/constants'

export default function Testimonials() {
  return (
    <StyledComponent id="testimonials">
      <div className="container">
        <h3 className="section-heading">Testimonials</h3>
        <div className="row">
          <blockquote>
            <p>
              Tim and his team did a fantastic job with our new house build. They were accommodating to our requests, and completed the project to a high standard, in time and
              budget. All of them were polite and happy to involve us in the process. We’d recommend T J Buckfield Ltd to anyone looking for a quality home builder.
            </p>
            <footer>&ndash; Mr &amp; Mrs Parr, Parkstone, Poole</footer>
          </blockquote>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  background-attachment: fixed;
  background-color: #2d2d2d;
  background-image: url(${CLOUDINARY_PATH}gravel-dark.jpg);
  background-position: top center;
  background-size: cover;
  color: #fff;
  padding: 8rem 3.5rem;

  h3 {
    color: #fff;
  }

  blockquote {
    margin: 0 auto;
    max-width: 88rem;
  }

  p {
    font-size: 1.4rem;
    text-align: center;

    &::before {
      content: '\\201c';
    }
    &::after {
      content: '\\201d';
    }
  }

  footer {
    text-align: right;
  }

  @media (min-width: 600px) {
    p {
      font-size: 1.6rem;
    }
  }
`
