import React from 'react'
import styled from 'styled-components'
import {CLOUDINARY_PATH} from './../constants/constants'

export default function Footer() {
  return (
    <StyledComponent id="phone-email">
      <div className="container">
        <div className="row">
          <p>
            <strong>Telephone</strong>
            <br />
            <a className="phone-link" href="tel:07970696925">
              07970 696925
            </a>
          </p>
          <p>
            <strong>Email</strong>
            <br />
            <a className="email-link" href="mailto:tim@tjbuckfieldltd.co.uk">
              tim@tjbuckfieldltd.co.uk
            </a>
          </p>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.footer`
  background-attachment: fixed;
  background-color: #4b0601;
  background-image: url(${CLOUDINARY_PATH}brick-wall-dark-red.jpg);
  background-position: top center;
  background-size: 113rem;
  color: #fff;
  font-size: 2.2rem;
  padding: 8rem 3.5rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    background-color: hsla(0, 0%, 0%, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .container {
    position: relative;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  strong {
    font-size: 2.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    .row {
      flex-direction: row;
    }

    p {
      font-size: 3rem;
      margin-bottom: 0;
      width: 50%;
    }

    strong {
      font-size: 3rem;
    }
  }

  @media (min-width: 1024px) {
    background-size: cover;

    p {
      font-size: 4rem;
    }

    strong {
      font-size: 4rem;
    }
  }
`
