import React from 'react'
import styled from 'styled-components'
import {CLOUDINARY_PATH, CLOUDINARY_PATH_THUMBNAIL} from './../constants/constants'
import ImageGallery from 'react-image-gallery'

const images = [
  {
    original: `${CLOUDINARY_PATH}photo-51.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-51.jpg`,
    originalAlt: 'Two storey extension, balcony and summer house',
    thumbnailAlt: 'Thumbnail of Two storey extension, balcony and summer house',
    originalTitle: 'Two storey extension, balcony and summer house',
  },
  {
    original: `${CLOUDINARY_PATH}photo-46.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-46.jpg`,
    originalAlt: 'Loft conversion and extension',
    thumbnailAlt: 'Thumbnail of Loft conversion and extension',
    originalTitle: 'Loft conversion and extension',
  },
  {
    original: `${CLOUDINARY_PATH}2024-03-28.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}2024-03-28.jpg`,
    originalAlt: 'Example work by T J Buckfield Ltd',
    thumbnailAlt: 'Example work by T J Buckfield Ltd',
    originalTitle: 'Example work by T J Buckfield Ltd',
  },
  {
    original: `${CLOUDINARY_PATH}photo-50.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-50.jpg`,
    originalAlt: 'Double storey extension',
    thumbnailAlt: 'Thumbnail of Double storey extension',
    originalTitle: 'Double storey extension',
  },
  {
    original: `${CLOUDINARY_PATH}photo-53.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-53.jpg`,
    originalAlt: 'Double bungalow',
    thumbnailAlt: 'Thumbnail of Double bungalow',
    originalTitle: 'Double bungalow',
  },
  {
    original: `${CLOUDINARY_PATH}photo-49.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-49.jpg`,
    originalAlt: 'Old barn to pair of semis',
    thumbnailAlt: 'Thumbnail of Old barn to pair of semis',
    originalTitle: 'Old barn to pair of semis',
  },
  {
    original: `${CLOUDINARY_PATH}photo-19.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-19.jpg`,
    originalAlt: 'Four bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Four bedroom detatched new build',
    originalTitle: 'Four bedroom detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-47.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-47.jpg`,
    originalAlt: 'Complete refurbishment',
    thumbnailAlt: 'Thumbnail of Complete refurbishment',
    originalTitle: 'Complete refurbishment',
  },
  {
    original: `${CLOUDINARY_PATH}photo-48.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-48.jpg`,
    originalAlt: 'Complete refurbishment',
    thumbnailAlt: 'Thumbnail of Complete refurbishment',
    originalTitle: 'Complete refurbishment',
  },
  {
    original: `${CLOUDINARY_PATH}photo-07.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-07.jpg`,
    originalAlt: 'Complete rebuild',
    thumbnailAlt: 'Thumbnail of Complete rebuild',
    originalTitle: 'Complete rebuild',
  },
  {
    original: `${CLOUDINARY_PATH}photo-36.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-36.jpg`,
    originalAlt: 'Five bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Five bedroom detatched new build',
    originalTitle: 'Five bedroom detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-32.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-32.jpg`,
    originalAlt: 'Side extension and loft conversion',
    thumbnailAlt: 'Thumbnail of Side extension and loft conversion',
    originalTitle: 'Side extension and loft conversion',
  },
  {
    original: `${CLOUDINARY_PATH}photo-56.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-56.jpg`,
    originalAlt: 'Four bedroom semi-detatched new build',
    thumbnailAlt: 'Thumbnail of Four bedroom semi-detatched new build',
    originalTitle: 'Four bedroom semi-detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-37.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-37.jpg`,
    originalAlt: 'Four bedroom semi-detatched new build',
    thumbnailAlt: 'Thumbnail of Four bedroom semi-detatched new build',
    originalTitle: 'Four bedroom semi-detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-39.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-39.jpg`,
    originalAlt: 'Complete rebuild',
    thumbnailAlt: 'Thumbnail of Complete rebuild',
    originalTitle: 'Complete rebuild',
  },
  {
    original: `${CLOUDINARY_PATH}photo-61.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-61.jpg`,
    originalAlt: 'Example work by T J Buckfield Ltd',
    thumbnailAlt: 'Example work by T J Buckfield Ltd',
    originalTitle: 'Example work by T J Buckfield Ltd',
  },
  {
    original: `${CLOUDINARY_PATH}photo-60.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-60.jpg`,
    originalAlt: 'Example work by T J Buckfield Ltd',
    thumbnailAlt: 'Example work by T J Buckfield Ltd',
    originalTitle: 'Example work by T J Buckfield Ltd',
  },
  {
    original: `${CLOUDINARY_PATH}photo-59.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-59.jpg`,
    originalAlt: 'Example work by T J Buckfield Ltd',
    thumbnailAlt: 'Example work by T J Buckfield Ltd',
    originalTitle: 'Example work by T J Buckfield Ltd',
  },
  {
    original: `${CLOUDINARY_PATH}photo-58.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-58.jpg`,
    originalAlt: 'Example work by T J Buckfield Ltd',
    thumbnailAlt: 'Example work by T J Buckfield Ltd',
    originalTitle: 'Example work by T J Buckfield Ltd',
  },
  {
    original: `${CLOUDINARY_PATH}photo-57.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-57.jpg`,
    originalAlt: 'Example work by T J Buckfield Ltd',
    thumbnailAlt: 'Example work by T J Buckfield Ltd',
    originalTitle: 'Example work by T J Buckfield Ltd',
  },
  {
    original: `${CLOUDINARY_PATH}photo-44.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-44.jpg`,
    originalAlt: 'A single storey extension',
    thumbnailAlt: 'Thumbnail of A single storey extension',
    originalTitle: 'A single storey extension',
  },
  {
    original: `${CLOUDINARY_PATH}photo-45.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-45.jpg`,
    originalAlt: 'Renovation with new garage',
    thumbnailAlt: 'Thumbnail of Renovation with new garage',
    originalTitle: 'Renovation with new garage',
  },
  {
    original: `${CLOUDINARY_PATH}photo-42.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-42.jpg`,
    originalAlt: 'Bungalow (Before &amp; After)',
    thumbnailAlt: 'Thumbnail of Bungalow (Before &amp; After)',
    originalTitle: 'Bungalow (Before &amp; After)',
  },
  {
    original: `${CLOUDINARY_PATH}photo-54.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-54.jpg`,
    originalAlt: 'Complete rebuild raid roof and extensions to rear',
    thumbnailAlt: 'Thumbnail of Complete rebuild raid roof and extensions to rear',
    originalTitle: 'Complete rebuild raid roof and extensions to rear',
  },
  {
    original: `${CLOUDINARY_PATH}photo-55.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-55.jpg`,
    originalAlt: 'Link to garage and rooms over',
    thumbnailAlt: 'Thumbnail of Link to garage and rooms over',
    originalTitle: 'Link to garage and rooms over',
  },
  {
    original: `${CLOUDINARY_PATH}photo-43.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-43.jpg`,
    originalAlt: 'Two story extension',
    thumbnailAlt: 'Thumbnail of Two story extension',
    originalTitle: 'Two story extension',
  },
  {
    original: `${CLOUDINARY_PATH}photo-08.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-08.jpg`,
    originalAlt: 'Complete rebuild',
    thumbnailAlt: 'Thumbnail of Complete rebuild',
    originalTitle: 'Complete rebuild',
  },
  {
    original: `${CLOUDINARY_PATH}photo-25.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-25.jpg`,
    originalAlt: 'Three bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Three bedroom detatched new build',
    originalTitle: 'Three bedroom detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-17.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-17.jpg`,
    originalAlt: 'Double storey thatched with glass link',
    thumbnailAlt: 'Thumbnail of Double storey thatched with glass link',
    originalTitle: 'Double storey thatched with glass link',
  },
  {
    original: `${CLOUDINARY_PATH}photo-31.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-31.jpg`,
    originalAlt: 'Pair of two bedroom semi-detatched homes',
    thumbnailAlt: 'Thumbnail of Pair of two bedroom semi-detatched homes',
    originalTitle: 'Pair of two bedroom semi-detatched homes',
  },
  {
    original: `${CLOUDINARY_PATH}photo-40.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-40.jpg`,
    originalAlt: 'Three bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Three bedroom detatched new build',
    originalTitle: 'Three bedroom detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-38.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-38.jpg`,
    originalAlt: 'Two storey extension',
    thumbnailAlt: 'Thumbnail of Two storey extension',
    originalTitle: 'Two storey extension',
  },
  {
    original: `${CLOUDINARY_PATH}photo-41.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-41.jpg`,
    originalAlt: 'Four bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Four bedroom detatched new build',
    originalTitle: 'Four bedroom detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-18.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-18.jpg`,
    originalAlt: 'Indoor swimming pool room',
    thumbnailAlt: 'Thumbnail of Indoor swimming pool room',
    originalTitle: 'Indoor swimming pool room',
  },
  {
    original: `${CLOUDINARY_PATH}photo-35.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-35.jpg`,
    originalAlt: 'New build bungalow',
    thumbnailAlt: 'Thumbnail of New build bungalow',
    originalTitle: 'New build bungalow',
  },
  {
    original: `${CLOUDINARY_PATH}photo-27.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-27.jpg`,
    originalAlt: 'Three bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Three bedroom detatched new build',
    originalTitle: 'Three bedroom detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-24.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-24.jpg`,
    originalAlt: 'Two storey extension',
    thumbnailAlt: 'Thumbnail of Two storey extension',
    originalTitle: 'Two storey extension',
  },
  {
    original: `${CLOUDINARY_PATH}photo-14.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-14.jpg`,
    originalAlt: 'Two bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Two bedroom detatched new build',
    originalTitle: 'Two bedroom detatched new build',
  },
  {
    original: `${CLOUDINARY_PATH}photo-22.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-22.jpg`,
    originalAlt: 'Rear extension',
    thumbnailAlt: 'Thumbnail of Rear extension',
    originalTitle: 'Rear extension',
  },
  {
    original: `${CLOUDINARY_PATH}photo-23.jpg`,
    thumbnail: `${CLOUDINARY_PATH_THUMBNAIL}photo-23.jpg`,
    originalAlt: 'Four bedroom detatched new build',
    thumbnailAlt: 'Thumbnail of Four bedroom detatched new build',
    originalTitle: 'Four bedroom detatched new build',
  },
]

export default function OurWork() {
  return (
    <StyledComponent id="our-work">
      <div className="container">
        <h3 className="section-heading">Our work</h3>
        <div className="row">
          <ImageGallery items={images} lazyLoad={true} autoPlay={true} slideInterval="5000" />
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  background-attachment: fixed;
  background-color: #eaeaea;
  background-image: url(${CLOUDINARY_PATH}block-paving-light.jpg);
  background-position: top center;
  background-size: cover;
  padding: 8rem 3.5rem;
`
