import React, {useState} from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import {Link} from 'react-scroll'

export default function Navigation({title}) {
  const [navbarOpen, setNavbarOpen] = useState(false)

  function linkClicked(e) {
    setNavbarOpen(false)
  }

  const WINDOW_GLOBAL = typeof window !== 'undefined' && window
  const NAVBAR_HEIGHT = WINDOW_GLOBAL.innerWidth < 750 ? 0 : 79
  const SCROLL_DURATION_MS = 500

  return (
    <StyledComponent>
      <button className="toggle-button" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
        <span className="sr-only">{navbarOpen ? 'Hide navigation' : 'Show navigation'}</span>
        {navbarOpen ? <i className="cross-icon" aria-hidden="true" data-icon="&#x4d;" /> : <i className="hamburger-icon" aria-hidden="true" data-icon="&#x61;" />}
      </button>
      <AnimateHeight duration={250} height={navbarOpen ? 'auto' : 0}>
        <h1 className="mobile-nav-heading" aria-hidden="true">
          {title}
        </h1>
        <ul>
          <li>
            <Link to="about-us" spy={true} smooth={true} offset={NAVBAR_HEIGHT * -1} duration={SCROLL_DURATION_MS} onClick={linkClicked}>
              About us
            </Link>
          </li>
          <li>
            <Link to="services" spy={true} smooth={true} offset={NAVBAR_HEIGHT * -1} duration={SCROLL_DURATION_MS} onClick={linkClicked}>
              Services
            </Link>
          </li>
          <li>
            <Link to="our-work" spy={true} smooth={true} offset={NAVBAR_HEIGHT * -1} duration={SCROLL_DURATION_MS} onClick={linkClicked}>
              Our work
            </Link>
          </li>
          <li>
            <Link to="testimonials" spy={true} smooth={true} offset={NAVBAR_HEIGHT * -1} duration={SCROLL_DURATION_MS} onClick={linkClicked}>
              Testimonials
            </Link>
          </li>
          <li>
            <Link to="contact-us" spy={true} smooth={true} offset={NAVBAR_HEIGHT * -1} duration={SCROLL_DURATION_MS} onClick={linkClicked}>
              Contact us
            </Link>
          </li>
          <li>
            <Link to="phone-email" spy={true} smooth={true} offset={NAVBAR_HEIGHT * -1} duration={SCROLL_DURATION_MS} onClick={linkClicked}>
              Phone &amp; Email
            </Link>
          </li>
        </ul>
      </AnimateHeight>
    </StyledComponent>
  )
}

const StyledComponent = styled.nav`
  background-color: #e5e5e5;
  position: fixed;
  width: 100%;
  z-index: 100;

  .toggle-button {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    border: 0;
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 4rem;
  }

  .hamburger-icon {
    font-size: 4rem;
    transform: translate(0px, 5px);
  }

  .cross-icon {
    font-size: 4rem;
    transform: translate(-1px, 3px);
  }

  .mobile-nav-heading {
    color: #585858;
    font-size: 2.6rem;
    margin: 9rem 0 0 0;
    text-align: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0 auto;
    margin: 0;
    min-height: 100vh;
    padding: 4rem 0 0 0;
    text-transform: uppercase;
    width: 100%;
  }

  li {
    display: flex;
    justify-content: center;
    margin: 0 0 1.6rem 0;
  }

  a {
    color: #666;
    cursor: pointer;
    padding: 1rem;
    text-decoration: none;
  }

  @media (min-width: 750px) {
    padding: 2rem 0;

    .toggle-button {
      display: none;
    }

    .mobile-nav-heading {
      display: none;
    }

    ul {
      align-items: center;
      flex-direction: row;
      font-size: 1.4rem;
      justify-content: center;
      min-height: 0;
      padding: 0;
    }

    li {
      margin-bottom: 0;
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .rah-static--height-zero {
      height: auto !important;
    }

    .rah-static--height-zero > div {
      display: block !important;
    }
  }
`
