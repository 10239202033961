import React from 'react'
import styled from 'styled-components'
import {CLOUDINARY_PATH} from './../constants/constants'

export default function ContactUs() {
  return (
    <StyledComponent id="contact-us">
      <div className="container">
        <h3 className="section-heading">Contact us</h3>
        <div className="row">
          <p>Feel free to get in touch with us, so we can discuss your requirements&hellip;</p>
          <form name="T J Buckfield Website Contact Form" method="POST" data-netlify="true" netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <div className="form-row form-row--name-email">
              <div className="form-field">
                <label htmlFor="name">
                  <span className="sr-only">Name</span>
                  <input type="text" id="name" name="name" placeholder="Name" required />
                </label>
              </div>
              <div className="form-field">
                <label htmlFor="email">
                  <span className="sr-only">Email</span>
                  <input type="email" id="email" name="email" placeholder="Email address" required />
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-field">
                <label htmlFor="message">
                  <span className="sr-only">Message</span>
                  <textarea id="message" name="message" placeholder="Message" required />
                </label>
              </div>
            </div>
            <div className="form-row form-row--submit">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  background-attachment: fixed;
  background-color: #eaeaea;
  background-image: url(${CLOUDINARY_PATH}grass-light.jpg);
  background-position: top center;
  background-size: cover;
  padding: 8rem 3.5rem;

  .form-field {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .form-row {
    display: flex;
    flex-direction: column;

    &--submit {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  textarea {
    min-height: 20rem;
    resize: none;
  }

  button {
    border: 2px solid #333;
    border-radius: 3px;
    padding: 0.6rem 2rem;
    font-size: 2rem;
    line-height: 3.4rem;
  }

  @media (min-width: 700px) {
    .form-row--name-email {
      flex-direction: row;
      margin-bottom: 3rem;
    }

    .form-field {
      margin-bottom: 0;
      margin-right: 3rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`
