import React from 'react'
import styled from 'styled-components'
import Navigation from '../components/navigation'
import {CLOUDINARY_PATH} from './../constants/constants'

export default function Header({title, subtitle}) {
  return (
    <StyledComponent>
      <Navigation title={title} />
      <div className="intro">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.header`
  .intro {
    align-items: center;
    background-attachment: fixed;
    background-color: #4b0601;
    background-image: url(${CLOUDINARY_PATH}brick-wall-dark-red.jpg);
    background-position: top center;
    background-size: 113rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    min-height: 38rem;
    padding: 1rem 0;
    text-align: center;
  }

  h1 {
    color: #fff;
    font-size: 3.3rem;
    text-transform: uppercase;
    margin: 0 0 1rem 0;
  }

  h2 {
    background-color: hsla(0, 0%, 11%, 0.8);
    border: 1px solid hsla(0, 0%, 29%, 0.5);
    color: #fff;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.9rem;
    font-weight: 400;
    padding: 1.6rem 3.2rem;
    margin: 0;
  }

  @media (min-width: 400px) {
    h1 {
      font-size: 6.5vw;
    }
  }

  @media (min-width: 600px) {
    h2 {
      font-size: 3.2vw;
    }
  }

  @media (min-width: 750px) {
    .intro {
      margin-top: 7.9rem;
    }
  }

  @media (min-width: 1000px) {
    h2 {
      font-size: 3.2rem;
    }
    .intro {
      background-size: cover;
    }
  }

  @media (min-width: 1500px) {
    h1 {
      font-size: 9.7435rem;
    }
  }
`
