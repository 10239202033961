import React from 'react'
import styled from 'styled-components'
import {CLOUDINARY_PATH} from './../constants/constants'

export default function Services() {
  return (
    <StyledComponent id="services">
      <div className="container">
        <h3 className="section-heading">Services</h3>
        <div className="row row--1">
          <div className="service">
            <i className="circle-icon" aria-hidden="true" data-icon="&#xe009;" />
            <h4>Beautiful homes</h4>
            <p>We do new builds, extensions, loft conversions, and general building work.</p>
          </div>
          <div className="service">
            <i className="circle-icon" aria-hidden="true" data-icon="&#xE035;" />
            <h4>All trades</h4>
            <p>We take care of the planning, building, carpentry, plumbing, electrics and decorating.</p>
          </div>
          <div className="service">
            <i className="circle-icon" aria-hidden="true" data-icon="&#x6C;" />
            <h4>Project management</h4>
            <p>We manage the whole project for you so you don&rsquo;t have to worry.</p>
          </div>
          <div className="service">
            <i className="circle-icon" aria-hidden="true" data-icon="&#xE108;" />
            <h4>Certified</h4>
            <p>We have both NHBC and CRL certification for your peace of mind.</p>
          </div>
        </div>
        <div className="row row--2">
          <div className="service">
            <i className="circle-icon" aria-hidden="true" data-icon="&#xE031;" />
            <h4>Experienced</h4>
            <p>We have over 25 years experience building stunning homes in Dorset.</p>
          </div>
          <div className="service">
            <i className="circle-icon" aria-hidden="true" data-icon="&#xE106;" />
            <h4>Job well done</h4>
            <p>We have completed hundreds of projects in the Dorset area.</p>
          </div>
          <div className="service">
            <i className="circle-icon" aria-hidden="true" data-icon="&#x76;" />
            <h4>Talk to us</h4>
            <p>We pride ourselves on being easy to discuss things with and we&rsquo;ll keep you updated every step of the way.</p>
          </div>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  background-attachment: fixed;
  background-color: #2d2d2d;
  background-image: url(${CLOUDINARY_PATH}/wood-dark.jpg);
  background-position: top center;
  background-size: cover;
  color: #fff;
  padding: 8rem 3.5rem;

  .service {
    margin: 0 0 5rem 0;
  }

  .circle-icon {
    align-items: center;
    border-radius: 5rem;
    border: 0.4rem solid #fff;
    display: flex;
    flex-direction: column;
    font-size: 5rem;
    height: 10rem;
    justify-content: center;
    margin: 0 auto 2rem auto;
    width: 10rem;
  }

  h3,
  h4 {
    color: #fff;
  }

  h4 {
    font-weight: 400;
    font-size: 1.8rem;
    margin: 0 0 1rem 0;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    text-align: center;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    .service {
      margin-bottom: 0;
    }
    .row {
      column-gap: 5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 5rem;
    }
    .row--1 {
      margin-bottom: 5rem;
    }
  }

  @media (min-width: 1200px) {
    .row--1 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    .row--2 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }
`
