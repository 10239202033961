import React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'
import Header from '../components/header'
import AboutUs from '../components/about-us'
import Services from '../components/services'
import OurWork from '../components/our-work'
import Testimonials from '../components/testimonials'
import ContactUs from '../components/contact-us'
import Footer from '../components/footer'

import './../styles/styles.scss'

export default function Home({data}) {
  return (
    <div className="site-wrapper">
      <Helmet>
        <html lang="en" />
        <title>{data.site.siteMetadata.title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="msapplication-TileColor" content="#4d0202" />
        <meta name="theme-color" content="#4d0202"></meta>
        <meta name="google-site-verification" content="NT2RbueLXqKBfgRU32dUrNCaafashprkWHNPAdgpHa0" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <Header title={data.site.siteMetadata.title} subtitle={data.site.siteMetadata.description} />
      <main>
        <AboutUs />
        <Services />
        <OurWork />
        <Testimonials />
        <ContactUs />
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
